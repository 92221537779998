import React, { useEffect, useState } from 'react';

import styles from './Edit.module.scss';
import { HourBankBalance } from '../../../../models/HourBankBalance';
import { Alert, Button, DateTimePicker, Input, Modal, Row, TextInformation, Toast } from '@optimuminterfaces/revex-react-components/dist/components';
import { translation as t } from '@optimuminterfaces/revex-react-components/dist/translation';
import { convertMinutesToTimeStr, convertTimeStrToMinutes, MONTH_OF_YEAR_EN } from '@optimuminterfaces/revex-react-components/dist/utils/DateTimeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uuidv4 } from '@optimuminterfaces/revex-react-components/dist/utils/DeveloperUtil';
import { putHourBankBalance } from '../../../../services/hourBankBalances.services';
import { calculateCycleOfEnvironment } from '../../../../utils/DeveloperUtil';

interface EditProps {
	hourBankBalanceRaw: HourBankBalance,
	handleChanges: Function,
	Container?: any
}
const Edit = ({ hourBankBalanceRaw, handleChanges, Container = 'div' }: EditProps) => {
	const [hourBankBalance, setHourBankBalance] = useState<HourBankBalance>(hourBankBalanceRaw);
	const [confirmEditHourBankBalance, setConfirmEditHourBankBalance] = useState(false);
	const [hoursType, setHoursType] = useState<string[]>(['-','-','-','-','-','-','-','-','-','-','-','-']);
	const [toastData, setToastData] = useState<any>([]);
	const [loadingButtonSave,setLoadingButtonSave] = useState(false);
	const [closePeriodEdit, setClosePeriodEdit] = useState(false);

	const months = calculateCycleOfEnvironment();
	const TOAST_TIME = 2000;

	const internalHandleChange = (hour:string, index:number, month:string) => {
		let minutes:number = convertTimeStrToMinutes(hour);

		if(hoursType[index]==='-'){
			minutes = (minutes*-1);
		}

		setHourBankBalance({...hourBankBalance, [month]:minutes});
		

	}

	const internalAddToast = (toastDataParam:any, handleCloseCallback?:Function) => {
        toastDataParam.key = `toast-${uuidv4()}`;
        toastDataParam.closeChange = () => {
            setToastData((toastData: any[]) => toastData.filter(({ key }) => (key !== toastDataParam.key)));
            handleCloseCallback?.();
        };

        setToastData((toastData: any) => [ ...toastData, toastDataParam ]);
    };

	const changeHourType = (currentValue: string, index: number) =>{
		let value = currentValue==='-'?'+':'-';
		let ht = [...hoursType];
		ht[index]= value;
		setHoursType(ht);
	}

	useEffect(()=>{

		let hb:any = {...hourBankBalance};

		MONTH_OF_YEAR_EN.forEach((month, index) => {
			
			const m = month.toLowerCase();
			let value = Math.abs(hb[m]);
			
			hb = {...hb, [m]: (hoursType[index]==='-'?value*-1: value)}
	
		});

		setHourBankBalance({...hb});
		
		
	},[hoursType]);

	useEffect(() => {
		let closeEdit = false;
		let hb:any = {...hourBankBalance};

		let hbRaw:any = {...hourBankBalanceRaw};
		MONTH_OF_YEAR_EN.forEach((month, index) => {
			let closeKey = 'closed'+(month.charAt(0).toUpperCase()+ month.slice(1));
			const m = month.toLowerCase();
			if(hbRaw[closeKey]===true && parseInt(hb[m]) !== hbRaw[m]){
				closeEdit = true;
			}
		});

		setClosePeriodEdit(closeEdit);
	},[hourBankBalance]);

	useEffect(()=>{
		let ht = [...hoursType];

		let hb:any = {...hourBankBalance};

		MONTH_OF_YEAR_EN.forEach((month, index) => {
			const m = month.toLowerCase();
			let value = hb[m];
			ht[index] = value < 0?'-':'+';

		});

		setHoursType(ht);
	},[]);

	const saveAndUpdateHourBankBalance = async () => {
		setLoadingButtonSave(true);
		let isValid = true;
		let hb:any = {...hourBankBalance};
		MONTH_OF_YEAR_EN.forEach((month, index) => {
			const m = month.toLowerCase();
			const value:number = hb[m];
			if(isNaN(value)){
				isValid = false;
			}


		});

		if(!isValid){
			internalAddToast({
				show: true,
				title: 'Error',
				icon: 'times',
				message: 'Campos requeridos não foram preenchidos',
				danger: true,
				positionTopRight: true,
				showTime: TOAST_TIME
			});
			setLoadingButtonSave(false);
			setConfirmEditHourBankBalance(false);
			return;
		}else{
			try{
				const jsonReturned = await putHourBankBalance(hourBankBalance.id, {...hourBankBalance});
				if(jsonReturned){
					if(jsonReturned.status==='ERROR'){
						internalAddToast({
							show: true,
							title: 'Error',
							icon: 'times',
							message: jsonReturned.message,
							danger: true,
							positionTopRight: true,
							showTime: TOAST_TIME
						});
						setLoadingButtonSave(false);
						setConfirmEditHourBankBalance(false);
					}else{
						internalAddToast({
							show: true,
							title: 'Sucesso',
							icon: 'check',
							message: jsonReturned.message,
							success: true,
							positionTopRight: true,
							showTime: TOAST_TIME
						},() => setTimeout(() => { 
							handleChanges({
								status: 'success', 
								name: 'HourBankBalanceEditSave', 
								message: '',
								value: jsonReturned.data
							});
							setLoadingButtonSave(false); 
							setConfirmEditHourBankBalance(false);
	
						}, 1000));
					}
				}
			}catch(error){
				console.log(error);
				setLoadingButtonSave(false);
			}

		}

	}

	const renderInputs = () => {
		let inputs:any[] = [];
		MONTH_OF_YEAR_EN.forEach((month, index) => {
			const m = month.toLowerCase();
			let hb:any = {...hourBankBalance};
			inputs.push(
				<Row.Item sm={12} md={3} lg={3} key={m}>
				<Container className={styles['hour-month']}>
					
					<Container onClick={() => changeHourType(hoursType[index], index)}>{hoursType[index]}</Container>
					<DateTimePicker
						type='time'
						noSeconds
						maxHour={999}
						title={months[index].value}
						name={m}
						value={convertMinutesToTimeStr(hb[m]).replace('-', '')}
						handleChange={(value) => internalHandleChange(value, index, m)}
					/>
				</Container>
				</Row.Item>
			);
		});
		return inputs;
	} 
	return <Container className={styles['hour-bank-balance-edit']}>
		{!confirmEditHourBankBalance && <Row>
			<Row.Item sm={12} md={12} lg={12}>
				<Alert 
					reverse
					highlight
					icon='exclamation-circle' 
					text='Para mudar o tipo da hora de - negativo para + positivo ou de + positivo para - negativo, basta clicar na aba onde contém o texto que será realizado a troca'	/>
			</Row.Item>
			{renderInputs()}
			<Row.Item sm={12} md={12} lg={12}>
				<Container className={styles['actions']}>
					<Button 
						defaultColor 
						title={t('GENERIC.BUTTON.CANCEL.TEXT')}
						action={() => handleChanges?.(
							{
								name: 'HourBankBalanceEditCancel',
								message: 'Operação cancelada',
								status: 'error'
							}
						)}/>
					<Button 
						primary 
						title={t('GENERIC.BUTTON.SAVE.TEXT')} 
						icon='save'
						loading={loadingButtonSave}
						action={() => setConfirmEditHourBankBalance(true)}/>
				</Container>
			</Row.Item>
		</Row>
		}
		{confirmEditHourBankBalance &&
			<Row>
				<Row.Item>
					
					<TextInformation text={ closePeriodEdit===true?'Você está alterando o valor do banco de horas do colaborador em um período já fechado, tem certeza que deseja continuar?':'Tem certeza que deseja salvar?'}
						icon='question-circle'/>

					<Container className={styles['actions']}>
						<Button title={t('GENERIC.TEXT.NO')}
							loading={loadingButtonSave}
							action={() => setConfirmEditHourBankBalance(false)}
							defaultColor/>
						<Button title={t('GENERIC.TEXT.YES')}
							action={() => saveAndUpdateHourBankBalance()}
							loading={loadingButtonSave}
							primary/>
					</Container>
				</Row.Item>
			</Row>
		}
		{toastData?.map?.((toastDataE:any) => {
				const {key, ...td} = toastDataE;
                return <Toast key={key} {...td} />
		})}
	</Container>
}

export default Edit;